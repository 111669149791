import {
  UPDATE_ACCOUNT,
  LOGIN,
  VALIDATE_USER_EMAIL,
  SIGNUP,
  SIGNUP_PENDING,
  SIGNUP_FAILED,
  SEND_RESET_LINK,
  RESET_PASSWORD,
  GET_INVITE,
  ACCEPT_INVITE,
  SUBMIT_MFA_LOGIN,
  SET_ACCOUNT_STATUS,
  EXTERNAL_LOGIN
} from "@actions/account"
import { GLOBALS } from "@src/config"
import { post, get } from "@src/helpers/fetch"
import { addGlobalMessage } from "@api/global_messages"
import { validateCreditCard } from "@api/settings/billing/index"
import queryString from "query-string"
import Url from "url-parse"

/**
 * Updates state.account
 *
 * @param {Object} [payload]
 *
 * @returns {Function} A redux dispatch
 */

export const updateAccount = payload => dispatch => dispatch({ type: UPDATE_ACCOUNT, payload })

/**
 * Validates an email
 *
 * @returns {Function} A redux dispatch
 */

export const validateEmail = callback => (dispatch, getState) => dispatch(
  post(
    VALIDATE_USER_EMAIL,
    `users/validate-email`,
    { email: getState().account.email },
    callback
  )
)

/**
 * Logs a user in
 *
 * @returns {Function} A redux dispatch
 */

export const logIn = event => (dispatch, getState) => {
  event.preventDefault()
  event.stopPropagation()
  const { account } = getState()
  dispatch(
    post(
      LOGIN,
      "auth/login",
      {
        email: account.email.toLowerCase().trim(),
        password: account.password
      },
      res => {
        if (res.mfa_required) {
          dispatch({ type: SET_ACCOUNT_STATUS, payload: { status: "READY" } })
          getState().utilities.history.push("/mfa")
        } else {
          dispatch(redirectAfterAuthentication(res))
        }
      }
    )
  )
}

/**
 * Signs a user up
 *
 * @returns {Function} A redux dispatch
 */

export const signUp = (googleID = null) => (dispatch, getState) => {
  dispatch({ type: SIGNUP_PENDING })
  if (!getState().account.festival) {
    dispatch(createAccountWithSubscription(googleID))
  }
  else {
    dispatch(createAccount())
  }
}

const createAccountWithSubscription = (googleID) => (dispatch, getState) => {
  dispatch(
    validateCreditCard(
      token => dispatch(createAccount({ token, googleID })),
      error => dispatch({ type: SIGNUP_FAILED, payload: { error } })
    )
  )
}

const createAccount = (data = {}) => (dispatch, getState) => {
  const { account, utilities } = getState()
  dispatch(
    post(
      SIGNUP,
      "users",
      {
        email: account.email.toLowerCase().trim(),
        password: account.password,
        full_name: account.full_name,
        country_code: account.country_code,
        city: utilities.user_location.city,
        festival: account.festival,
        token: data.token,
        google_id: data.googleID
      },
      res => window.location.replace(res.redirectURL)
    )
  )
}

/**
 * Sends a password reset link to a user
 *
 * @returns {Function} A redux dispatch
 */

export const sendResetLink = event => (dispatch, getState) => {
  event.preventDefault()
  event.stopPropagation()
  dispatch(
    post(
      SEND_RESET_LINK,
      `auth/reset-link`,
      {
        email: getState().account.email
      },
      () => {
        dispatch(addGlobalMessage("If an account exists with this email address, you will receive password reset instructions shortly.", "success"))
      },
      error => {
        dispatch(addGlobalMessage("An error occurred, please try again.", "error"))
      }
    )
  )
}

/**
 * Resets the user password
 *
 * @returns {Function} A redux dispatch
 */

export const resetPassword = event => (dispatch, getState) => {
  event.preventDefault()
  event.stopPropagation()
  const { account, utilities } = getState()
  dispatch(
    post(
      RESET_PASSWORD,
      "auth/reset-password",
      {
        email: account.email.trim().toLowerCase(),
        password: account.password,
        reset_token: queryString.parse(window.location.search).token
      },
      () => {
        dispatch(addGlobalMessage("Password reset!", "success"))
        utilities.history.push("/login")
      }
    )
  )
}

/**
 * Submits a MFA attempt
 *
 * @returns {Function} A redux dispatch
 */

export const mfa = () => (dispatch, getState) =>
  dispatch(
    post(
      SUBMIT_MFA_LOGIN,
      "auth/mfa/validate-login",
      {
        google2fa_code: getState().account.google2fa_code,
        email: getState().account.email
      },
      () => dispatch(redirectAfterAuthentication())
    )
  )

/**
 * Fetches an org invite from the server
 *
 * @returns {Function} A redux dispatch
 */

export const getInvite = token => dispatch =>
  dispatch(get(GET_INVITE, `organizations/users/invites/${token}`))

export const acceptInvite = event => (dispatch, getState) => {
  event.preventDefault()
  event.stopPropagation()
  const { account } = getState()
  dispatch(
    post(
      ACCEPT_INVITE,
      `organizations/${account.organization_id}/users/${queryString.parse(window.location.search).token}`,
      {
        email: account.email.toLowerCase().trim(),
        password: account.password,
        full_name: account.full_name
      },
      () => dispatch(redirectAfterAuthentication())
    )
  )
}

/**
 * Redirects the user after auth is complete
 * If the user came from another app (app.cinesend.com, etc),
 * it will redirect them back to there. If not, it will
 * redirect to /
 *
 * @returns {Function}
 */
const redirectAfterAuthentication = (res = null) => (dispatch, getState) => {
  const redirect = decodeURIComponent(queryString.parse(window.location.search).redirect)
  // If there is no redirect, we can always go to the base /apps page
  if (!redirect || redirect === "undefined" || redirect.includes("logout")) {
    setTimeout(() => {
      getState().utilities.history.push("/settings/apps")
    }, 250)
    return
  }
  const url = Url(redirect)

  // If the redirect is on this domain, we can use state to change routes,
  // avoiding a page refresh
  if (url.origin === GLOBALS.URL && url.pathname) {
    setTimeout(() => {
      getState().utilities.history.push(url.pathname)
    }, 250)
    return
  }

  let redirectAllowed = false
  if (res) {
    Object.keys(res.user.app_permissions).forEach(key => {
      const obj = res.user.app_permissions[key]
      if (obj.url === url.origin && !obj.hide) {
        redirectAllowed = true
      }
    })
  }

  // If the redirect is another app (app.cinesend.com, etc),
  // we can replace the window location manually
  if (redirectAllowed) {
    window.location.replace(url.href)
    return
  }

  getState().utilities.history.push("/settings/apps")
}

export const externalLogin = (code, stateID) => (dispatch, getState) => {
  dispatch(
    post(
      EXTERNAL_LOGIN,
      'auth/external-login',
      { code, stateID },
      res => {
        if (res.mfa_required) {
          dispatch({ type: SET_ACCOUNT_STATUS, payload: { status: "READY" } })
          getState().utilities.history.push("/mfa")
        } else {
          dispatch(redirectAfterAuthentication(res))
        }
      }
    )
  );
}